import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Filter, FetchResourcesHook } from "../types";
import { Typography } from "@material-ui/core";
import FilterBox from "./FilterBox";
import SelectDropdown from "../SelectDropdown";
import { formatDateToMonthYear } from "../utils";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
  fetchResourcesHook: FetchResourcesHook;
}

const SKIP: number = 20;

export default function RangeFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: Props) {
  const classes = useStyles();

  const {
    data: fromFilterResponse,
    fetchNextPage: fetchNextFromFilterPage,
    hasNextPage: hasNextFromPage
  } = fetchResourcesHook(item?.from_resources, "", {
    getNextPageParam: (lastPage: any) => {
      const limit = lastPage?.pageSize * lastPage?.page + 1;
      return lastPage?.page < limit / lastPage?.pageSize &&
        Boolean(lastPage?.list?.length)
        ? lastPage?.pageSize + lastPage?.page * SKIP
        : undefined;
    }
  });

  const {
    data: toFilterResponse,
    fetchNextPage: fetchNextToFilterPage,
    hasNextPage: hasNextToPage
  } = fetchResourcesHook(item?.to_resources?.slice(4), "", {
    getNextPageParam: (lastPage: any) => {
      const limit = lastPage?.pageSize * lastPage?.page + 1;
      return lastPage?.page < limit / lastPage?.pageSize &&
        Boolean(lastPage?.list?.length)
        ? lastPage?.pageSize + lastPage?.page * SKIP
        : undefined;
    }
  });

  const [from, setFrom] = useState<string>("");
  const [fromFilter, setFromFilter] = useState<any>();
  useEffect(() => {
    if (fromFilterResponse?.pages?.length) {
      const filterList: any = [];

      fromFilterResponse.pages?.forEach((page: any) => {
        page?.list?.forEach((value: any) => {
          filterList.push(value);
        });
      });
      setFromFilter(filterList);
    }
  }, [fromFilterResponse]);

  const [to, setTo] = useState<string>("");
  const [toFilter, setToFilter] = useState<any>();
  useEffect(() => {
    if (toFilterResponse?.pages?.length) {
      const filterList: any = [];

      toFilterResponse.pages?.forEach((page: any) => {
        page?.list?.forEach((value: any) => {
          filterList.push(value);
        });
      });
      setToFilter(filterList);
    }
  }, [toFilterResponse]);

  useEffect(() => {
    if (from && to && from < to) {
      setSelection([`${from} - ${to}`]);
    }
  }, [from, to, setSelection]);

  const hasError = Boolean(from && to && from >= to);
  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <div className={classes.filterBody}>
          <SelectDropdown
            name="from"
            label="From"
            value={from}
            data={fromFilter?.map((value: any) => {
              return {
                id: "from-" + value,
                label: formatDateToMonthYear(value),
                value
              };
            })}
            infiniteScroll
            multiple={false}
            nextPage={fetchNextFromFilterPage}
            hasNextPage={hasNextFromPage}
            onChange={(e: any) => setFrom(e.target.value)}
            error={hasError}
          />
          <SelectDropdown
            name="to"
            label="To"
            value={to}
            data={toFilter?.map((value: any) => {
              return {
                id: "to-" + value,
                label: formatDateToMonthYear(value),
                value
              };
            })}
            infiniteScroll
            multiple={false}
            nextPage={fetchNextToFilterPage}
            hasNextPage={hasNextToPage}
            onChange={(e: any) => setTo(e.target.value)}
            error={hasError}
          />
        </div>
        {hasError && (
          <Typography color="error" variant="caption" className={classes.error}>
            From is higher than to
          </Typography>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBody: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minWidth: "450px",
      gap: theme.spacing(2),
      maxHeight: 200,
      paddingInline: theme.spacing(2)
    },
    filterListValue: {
      textTransform: "capitalize",
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
      flex: 1
    },
    error: {
      display: "flex",
      width: "100%",
      paddingTop: 8,
      paddingLeft: 18
    }
  })
);
