import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { formatDateWithPattern, parseStringToDate } from "../utils";
import FilterBox from "./FilterBox";
import { BrowserDateField } from "../../BrowserDateField/BrowserDateField";
import { format } from "date-fns";
import { Box } from "@material-ui/core";

export const DATE_PATTERN: string = "dd/MM/yyyy";
export const DC_DATE_FORMAT: string = "yyyy-MM-dd";

export default function DateRangeFilter({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  ...props
}: any) {
  const val1 = selection?.[0];
  const val2 = selection?.[1];
  const [dateSelectedFrom, setDateSelectedFrom] = React.useState<any>(
    val1 ? parseStringToDate(val1, DATE_PATTERN) : new Date()
  );
  const [dateSelectedTo, setDateSelectedTo] = React.useState<any>(
    val2 ? parseStringToDate(val2, DATE_PATTERN) : new Date()
  );
  const classes = useStyles();

  React.useEffect(() => {
    const from = formatDateValue(dateSelectedFrom);
    const to = formatDateValue(dateSelectedTo);

    setSelection([from, to]);
  }, [setSelection, dateSelectedTo, dateSelectedFrom]);

  const formatDateValue = (date: any) => {
    if (date) {
      const dateToIso = new Date(date).toISOString();
      const formatedDate = formatDateWithPattern(dateToIso, DATE_PATTERN);
      return formatedDate;
    }
  };

  const handleFromDateChange = (date: any) => {
    setDateSelectedFrom(parseStringToDate(date, DC_DATE_FORMAT));
  };

  const handleToDateChange = (date: any) => {
    setDateSelectedTo(parseStringToDate(date, DC_DATE_FORMAT));
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        showReset
      >
        <div className={classes.filterBody}>
          <Box pb={2}>
            <BrowserDateField
              name="date-from"
              value={format(dateSelectedFrom, DC_DATE_FORMAT)}
              label="From"
              fullWidth={true}
              onChange={(date: string) => handleFromDateChange(date)}
              maxDate={format(dateSelectedTo, DC_DATE_FORMAT)}
              size="small"
            />
          </Box>
          <BrowserDateField
            name="date-to"
            value={format(dateSelectedTo, DC_DATE_FORMAT)}
            label="To"
            fullWidth={true}
            onChange={(date: string) => handleToDateChange(date)}
            minDate={format(dateSelectedFrom, DC_DATE_FORMAT)}
            size="small"
          />
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBody: {
      paddingInline: theme.spacing(2)
    }
  })
);
