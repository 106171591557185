import React from "react";
import {
  AffinityLogomark,
  VodafoneLogo,
  DWSLogotypeLight
} from "@akj-dev/design-system";
import { parseBrandFromUrl } from "../../../helpers/brand";

export function SubNavLogo(props: React.SVGProps<SVGSVGElement>) {
  const brand = parseBrandFromUrl();
  switch (brand) {
    case "VODAFONE":
      return <VodafoneLogo {...props} />;
    case "DWS":
      return <DWSLogotypeLight />;
    case "AFFINITY":
    default:
      return <AffinityLogomark {...props} />;
  }
}
