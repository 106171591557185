import React from "react";
import { AffinityIconProps } from "../../shared/typings";

export const AffinityHomeIcon = ({
  size = "40px",
  inactive = false
}: AffinityIconProps) => {
  return (
    <svg
      style={inactive ? { opacity: 0.5 } : {}}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="home" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M21.4170399,11.9075647 L23.3948204,13.5231869 C23.448362,13.5661532 23.4918033,13.656772 23.4918033,13.7254167 C23.4918033,13.7761088 23.4659348,13.8491863 23.4340505,13.8885748 L23.1065946,14.2909447 C23.0635966,14.3437581 22.9734845,14.3866294 22.9053779,14.3866294 C22.8545593,14.3866294 22.7812917,14.3606027 22.74184,14.32856 L21.4170399,13.2462964 L21.4170399,21.1266105 C21.4170399,21.4129682 21.1846355,21.6453726 20.8982778,21.6453726 L16.748181,21.6560746 C16.4618233,21.6560746 16.2294189,21.4236702 16.2294189,21.1373125 L16.2294189,16.9846194 L14.1543705,16.9748989 L14.1543705,21.1249957 C14.1543705,21.4113534 13.9219661,21.6437578 13.6356084,21.6437578 L9.48551165,21.6437578 C9.19915396,21.6437578 8.96674956,21.4113534 8.96674956,21.1249957 L8.96674956,13.2462964 L7.64194946,14.32856 C7.60271934,14.3599694 7.53011671,14.3854579 7.47986796,14.3854579 C7.41185643,14.3854579 7.32180764,14.3426816 7.27884132,14.2899631 L6.95135375,13.8876249 C6.91940606,13.8482048 6.89344262,13.7750006 6.89344262,13.7242452 C6.89344262,13.6562337 6.93625063,13.5661532 6.98896907,13.5231869 L14.5372679,7.36222211 C14.6954548,7.23468465 14.988715,7.13114754 15.1918947,7.13114754 C15.3950744,7.13114754 15.6883346,7.23468465 15.8465216,7.36222211 L20.3795157,11.0622966 L20.3795157,9.39804038 C20.3795157,9.29067208 20.4666832,9.20350459 20.5740515,9.20350459 L21.2225041,9.20350459 C21.3298724,9.20350459 21.4170399,9.29067208 21.4170399,9.39804038 L21.4170399,11.9075647 Z M20.379199,20.6104448 L20.3759378,20.6104448 L20.3795157,12.3997301 L15.1918947,8.16632869 L10.0042737,12.3997301 L10.0042737,20.6104448 L13.1165297,20.6104448 L13.1165297,16.4558202 C13.1165297,16.1694625 13.3489341,15.9370581 13.6352918,15.9370581 L13.6369066,15.9370581 L16.7494792,15.945797 C17.0340637,15.9475702 17.2657399,16.1799746 17.2666265,16.4645591 L17.2666265,20.6201652 L20.379199,20.6104448 Z"
          id="Shape"
          fill="#271D3E"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
