import React from "react";
import { Typography, Button } from "@material-ui/core";
import { PlaceholderImage } from "@akj-dev/affinity-wrapper";
import Main from "../layout/Main";
import header from "../images/header.jpg";

export const Home = () => {
  return (
    <Main title="Billing (Coming Soon)">
      <PlaceholderImage
        src={header}
        title="Billing Header"
        alt="Billing Header"
        width="100%"
      />
      <Typography variant="body1" gutterBottom>
        Powerful, flexible and scalable billing engine designed to give your
        billing team peace of mind that all bill runs are quick and accurate.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We’re currently in the process of moving Billing into the Affinity
        platform from the Wholesale Portal, giving you a brand-new, slicker,
        faster user experience with a seamless integration with Sales and
        upcoming Affinity products.
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you’re already an Affinity Billing customer, we’ll let you know once
        the move is complete.
      </Typography>
      <Button
        size="medium"
        variant="contained"
        color="primary"
        href="https://aurora.io/products/affinity-billing/"
        target="_blank"
      >
        Find out more
      </Button>
    </Main>
  );
};
