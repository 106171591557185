import React, { ReactNode } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { Controller, UseControllerProps } from "react-hook-form";
import { getLabel } from "./utils/getLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export interface RHFSelectFieldProps {
  name: UseControllerProps["name"];
  label: string;
  rules?: UseControllerProps["rules"];
  children: ReactNode;
}

/**
 * reach-hook-form select field
 * Use inside a <FormProvider>
 */
export const RHFSelectField = ({
  name,
  label,
  rules,
  children
}: RHFSelectFieldProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field, fieldState, formState }) => {
        return (
          <FormControl
            fullWidth
            error={!!fieldState.error}
            disabled={formState.isSubmitting}
          >
            <InputLabel>{getLabel(label, rules)}</InputLabel>
            <Select {...field}>{children}</Select>
            {fieldState.error && (
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error.message || "Required"}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
