import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  AppSubdomains,
  getPathForCrossAppRedirect
} from "@akj-dev/affinity-platform";
import { SearchField } from "../SearchField";

export default function GlobalSearchToolbar() {
  const [query, setQuery] = useQueryParam(
    "query",
    withDefault(StringParam, "")
  );
  const [searchQuery, setSearchQuery] = useState(query);

  useEffect(() => {
    setSearchQuery(query);
  }, [query, setSearchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    if (!e.target.value.length) setQuery("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    window.location.href = getPathForCrossAppRedirect(
      AppSubdomains.HOME,
      `/search?query=${searchQuery}`
    );
  };

  return (
    <Box pl={19}>
      <SearchField
        value={searchQuery}
        onChange={handleChange}
        onSubmit={handleSubmit}
        margin="dense"
      />
    </Box>
  );
}
