import React, {
  Fragment,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent
} from "react";

import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grow,
  Popper,
  Paper,
  List,
  ListItemText,
  ListItem,
  ClickAwayListener,
  Checkbox
} from "@material-ui/core";

import { Filter } from "./types";

interface Props {
  filters: Filter[];
  updateFilterVisibility: (filterId: string, value: boolean) => void;
}

// was in packages/affinity-hub-fe/src/api/filter.ts
const MIN_FILTERS_COUNT = 7;

const MoreFilters = ({ filters, updateFilterVisibility }: Props) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);

  const onMoreFiltersClick = (event: ReactMouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShowPopup(prevShowPopup => !prevShowPopup);
  };

  const onClickAway = (event: ReactMouseEvent<Document, MouseEvent>) => {
    event.stopPropagation();
    setShowPopup(false);
  };

  if (filters?.length <= MIN_FILTERS_COUNT) {
    return <></>;
  }

  return (
    <div className={classes.ctr} ref={anchorRef}>
      <Button
        variant="contained"
        color="primary"
        onClick={onMoreFiltersClick}
        data-cy="more-filters-button"
        className={classes.button}
      >
        More filters
      </Button>
      <Popper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={onClickAway}>
                <List id="filters-menu" data-cy="all-filters-items">
                  {filters?.map((filter: Filter, index: any) => (
                    <ListItem
                      className={classes.filterItem}
                      key={`filter-${filter.id}`}
                      data-cy={"filter-item-" + index}
                    >
                      <div className={classes.filterBlock}>
                        <ListItemText
                          className={classes.filterName}
                          primary={filter.name}
                        />
                        <Checkbox
                          color="primary"
                          className={classes.checkbox}
                          data-cy={"filter-item-checkbox-" + index}
                          checked={filter.showToggle}
                          onChange={e =>
                            updateFilterVisibility(filter.id, e.target.checked)
                          }
                        />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      marginLeft: "auto"
    },
    button: {
      padding: "5px 15px"
    },
    popper: {
      zIndex: 1100
    },
    filterItem: {
      minWidth: "200px",
      height: "36px"
    },
    filterName: {
      float: "left"
    },
    filterBlock: {
      flex: "auto"
    },
    checkbox: {
      float: "right"
    }
  })
);

export default MoreFilters;
