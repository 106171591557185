import { affinityColors as colors } from "@akj-dev/design-system";

import { Theme } from "@material-ui/core";
import { parseBrandFromUrl } from "../../../../helpers/brand";

export const getStylesConfig = (theme: Theme) => {
  switch (parseBrandFromUrl()) {
    case "VODAFONE":
      return {
        bgColor: "transparent",
        fgColor: theme.palette.primary.main,
        round: true,
        activeBorderColor: theme.palette.primary.main
      };

    case "DWS":
      return {
        bgColor: theme.palette.primary.main,
        fgColor: "white",
        round: false,
        activeBorderColor: theme.palette.secondary.main
      };

    case "AFFINITY":
    default:
      return {
        bgColor: undefined,
        fgColor: undefined,
        round: false,
        activeBorderColor: colors.neutralStorm.value
      };
  }
};
