import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Filter, FetchResourcesHook } from "../types";
import FilterBox from "./FilterBox";
import MenuItem from "@material-ui/core/MenuItem";
import { useEffect } from "react";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
  fetchResourcesHook: FetchResourcesHook;
}

export default function EnumFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: Props) {
  const classes = useStyles();
  const [values, setValues] = useState(item.values || []);

  const { data: filterResponse } = fetchResourcesHook(
    item?.resources,
    "", // max 25 items loaded
    { enabled: !item.values?.length }
  );

  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList = filterResponse.pages?.flatMap((page: any) =>
        page?.list?.map((value: string) => value)
      );
      setValues(filterList);
    }
  }, [filterResponse]);

  const handleMultiSelect = (listItem: any) => {
    if (
      !selection.some((current: any) => current === listItem) &&
      options?.MAX_COUNT &&
      selection.length < options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else if (
      !selection.some((current: any) => current === listItem) &&
      !options?.MAX_COUNT
    ) {
      setSelection([...selection, listItem]);
    } else {
      let selectionAfter = selection;
      selectionAfter = selectionAfter.filter(
        (current: any) => current !== listItem
      );
      setSelection([...selectionAfter]);
    }
  };

  const isSelected = (listItem: any) => {
    if (selection.find((current: any) => current === listItem)) {
      return true;
    }
    return false;
  };

  const valueDisplayFunc = item.valueDisplayFunc
    ? item.valueDisplayFunc
    : (val: any) => val;

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <div id="scrollableDiv" className={classes.filterBody}>
          {values?.map((value: any) => {
            return (
              <MenuItem
                key={value}
                button
                onClick={() => handleMultiSelect(value)}
                selected={isSelected(value)}
                className={classes.filterListValue}
              >
                {valueDisplayFunc(value)}
              </MenuItem>
            );
          })}
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBody: {
      maxHeight: 200,
      overflowY: "auto",
      paddingInline: theme.spacing(2)
    },
    filterListValue: {
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  })
);
