import React from "react";
import { RHFTextField } from "../../../atoms/form-fields/RHFTextField";
import { useFormContext } from "react-hook-form";
import { RHFSelectField } from "../../../atoms/form-fields/RHFSelectField";
import { MenuItem, Grid } from "@material-ui/core";
import { IndexedCardInfo } from "../../../atoms/indexed-card-info";
import {
  isValidUKCompanyRegNumber,
  isValidVATNumber
} from "../helpers/validators";

interface AccountDetailsProps {
  cardIndex: string;
}
export const AccountDetails = ({ cardIndex }: AccountDetailsProps) => {
  const { watch } = useFormContext();
  const business_entity_type = watch("business_entity_type");

  return (
    <IndexedCardInfo index={cardIndex} title="Account Details">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RHFTextField
            name="name"
            label="Company Name"
            rules={{
              required: true,
              validate: val => {
                return val.length <= 40
                  ? true
                  : "Must be 40 characters or less";
              }
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFSelectField
            name="business_entity_type"
            label="Company Type"
            rules={{ required: true }}
          >
            <MenuItem value="Limited Company (LTD)">
              Limited Company (LTD)
            </MenuItem>
            <MenuItem value="Sole Trader / Owner Manager">
              Sole Trader / Owner Manager
            </MenuItem>
            <MenuItem value="Limited Liability Partnership">
              Limited Liability Partnership
            </MenuItem>
            <MenuItem value="Charity">Charity</MenuItem>
          </RHFSelectField>
        </Grid>
        {business_entity_type === "Charity" && (
          <Grid item sm={6}>
            <RHFTextField label="Charity Number" name="charity_number" />
          </Grid>
        )}
        <Grid item sm={6}>
          <RHFTextField
            label="Company Registration Number"
            name="registered_number"
            rules={{
              required: business_entity_type === "Limited Company (LTD)",
              validate: val =>
                business_entity_type === "Limited Company (LTD)"
                  ? isValidUKCompanyRegNumber(val)
                  : val
                  ? isValidUKCompanyRegNumber(val)
                  : true
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFSelectField label="No. of employees" name="employees">
            <MenuItem value="0">Not Provided</MenuItem>
            <MenuItem value="1">1-10</MenuItem>
            <MenuItem value="11">11-20</MenuItem>
            <MenuItem value="21">21-50</MenuItem>
            <MenuItem value="51">51-100</MenuItem>
            <MenuItem value="101">101-499</MenuItem>
            <MenuItem value="500">500-999</MenuItem>
            <MenuItem value="1000">1000+</MenuItem>
          </RHFSelectField>
        </Grid>
        {business_entity_type !== "Charity" && (
          <Grid item sm={6}>
            <RHFTextField
              label="VAT Number"
              name="vat_number"
              rules={{ validate: val => (val ? isValidVATNumber(val) : true) }}
            />
          </Grid>
        )}
      </Grid>
    </IndexedCardInfo>
  );
};
