import React, { useEffect, useState } from "react";
import _ from "lodash";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FilteredList } from "./FilteredList";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { getAddressLine } from "../helpers/getAddressLine";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { UPRN, WLR3Address } from "../types";
import { StatusChip } from "../../../StatusChip/StatusChip";
import { useTheme } from "@material-ui/core/styles";
import { getErrorMessage } from "@akj-dev/affinity-platform";
import { FetchWLR3AddressSearch } from "../types";

interface ChooseAddressDialogProps {
  open: boolean;
  onClose: () => void;
  postcode: string;
  includeUPRN?: boolean;
  setAddress: (wlr3Address: WLR3Address) => void;
  setUPRN?: (uprn: UPRN) => void;
  /**
   * accountId is necessary when it can't be inferred from the request according to @davet
   * i.e. when directly to DC and not through Kong
   * This is to do with multi-tenancy and each tenant having their own SI key
   */
  accountId?: string;
  api: FetchWLR3AddressSearch;
}

export const ChooseAddressDialog = ({
  open,
  onClose,
  postcode,
  setAddress,
  setUPRN,
  includeUPRN,
  accountId,
  api
}: ChooseAddressDialogProps) => {
  const theme = useTheme();
  const [uprnSelect, setUprnSelect] = useState(false);
  const [addresses, setAddresses] = useState<WLR3Address[]>([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<false | WLR3Address>(
    false
  );

  useEffect(() => {
    if (open) {
      (async () => {
        setFetching(true);
        setAddresses([]);
        setError("");
        try {
          const response: any = await api(postcode, !!includeUPRN, accountId);
          if (response.addressMatches) {
            // Filter out the non-GOLD addresses
            // They don't have CSS database codes which are required for broadband for instance.
            // TODO: Get the API fixed so this filter can be specified in the request.
            setAddresses(
              response.addressMatches.filter(
                (a: any) => a.addressCategory === "GOLD"
              )
            );
          } else {
            throw new Error("No matching addresses");
          }
        } catch (e) {
          setError(getErrorMessage(e));
        }
        setFetching(false);
      })();
    }
  }, [postcode, open, includeUPRN, accountId, api]);

  const selectAddress = (address: WLR3Address) => {
    setSelectedAddress(address);
    setAddress(address);
    if (includeUPRN && !_.isEmpty(address.UPRNs)) {
      setUprnSelect(true);
    } else {
      onClose();
    }
  };

  const selectUPRN = (uprn: UPRN) => {
    if (setUPRN) setUPRN(uprn);
    setUprnSelect(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{uprnSelect ? "Choose UPRN" : "Choose Address"}</DialogTitle>
      <DialogContent>
        {(() => {
          if (fetching) return <LinearProgress />;
          if (error)
            return (
              <StatusChip
                type="error"
                title="Error finding address"
                message={error}
              />
            );
          if (uprnSelect && selectedAddress)
            return (
              <>
                <FilteredList<UPRN>
                  items={selectedAddress.UPRNs || []}
                  isUprn={true}
                  renderRow={(uprn, i, onClick) => (
                    <ListItem key={uprn.uprn} onClick={onClick} button>
                      <ListItemText
                        primary={
                          <>
                            <span
                              style={{
                                color: theme.palette.text.disabled
                              }}
                            >
                              {uprn.uprn}
                            </span>{" "}
                            {uprn.address}
                          </>
                        }
                      />
                    </ListItem>
                  )}
                  handleItemSelected={selectUPRN}
                />
                <StatusChip
                  type="info"
                  message="A UPRN provides more accurate pricing for Virgin products."
                />
              </>
            );
          if (addresses) {
            if (addresses.length < 1)
              return (
                <StatusChip
                  type="error"
                  title="Sorry, no gold addresses found."
                  message="To progress this order please raise manually with the provisioning team."
                />
              );
            return (
              <FilteredList<WLR3Address>
                items={addresses}
                renderRow={(address, i, onClick) => (
                  <ListItem key={i} onClick={onClick} button>
                    <ListItemText primary={getAddressLine(address)} />
                  </ListItem>
                )}
                handleItemSelected={selectAddress}
              />
            );
          }
          return false;
        })()}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
