import React from "react";
import { Route, Switch } from "react-router-dom";

import { RoutingObject } from "../../helpers/navigation";

interface RoutesProps {
  routes: RoutingObject;
}

export const SwitchRoutes: React.FC<RoutesProps> = ({ routes }) => (
  <Switch>
    {routes.flat.map(route => (
      <Route path={route.path} key={route.path}>
        {route.subject}
      </Route>
    ))}
  </Switch>
);
