import React from "react";
import { Grid } from "@material-ui/core";
import { RHFTextField } from "../../../atoms/form-fields/RHFTextField";
import { FetchWLR3AddressSearch, WLR3Address } from "../../AddressPicker/types";
import { useFormContext, UseFormSetValue } from "react-hook-form";
import { RHFPostcodeLookup } from "../../../atoms/form-fields/RHFPostcodeLookup";

type Props = {
  prefix: string;
  suffix?: string;
  api: FetchWLR3AddressSearch;
  accountId?: string;
};

export const AddressFields = ({
  prefix = "",
  suffix = "",
  api,
  accountId
}: Props) => {
  const { getValues } = useFormContext();

  const handleAddressSelect = (
    address: WLR3Address,
    setValue: UseFormSetValue<any>
  ) => {
    // Note the account/create address format is subtly different to elsewhere,
    // hence mapWLR3AddressToDCOrderProduct can't be used here. See TP40973 and
    // https://miro.com/app/board/uXjVOQpjvFc=/
    const dcAddress = {
      [`${prefix}building_number${suffix}`]: address.thoroughfareNumber,
      [`${prefix}building${suffix}`]:
        address.premisesName || address.subPremises || address.organisationName,
      [`${prefix}street${suffix}`]:
        address.thoroughfareName || address.dependentThoroughfareName,
      [`${prefix}post_town${suffix}`]:
        address.postTown || address.locality || address.doubleDependentLocality,
      [`${prefix}region${suffix}`]: address.county,
      [`${prefix}locale${suffix}`]: address.locality,
      [`${prefix}postcode${suffix}`]: address.postcode,
      [`${prefix}country${suffix}`]: address.country || "United Kingdom"
    };

    Object.keys(dcAddress).forEach(field => {
      setValue(field, dcAddress[field] || "");
    });
  };

  return (
    <>
      <Grid item sm={12}>
        <RHFPostcodeLookup
          name={`${prefix}postcode${suffix}`}
          required={true}
          api={api}
          accountId={accountId}
          onSelect={handleAddressSelect}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="Street Number"
          name={`${prefix}building_number${suffix}`}
          rules={{
            validate: val =>
              !val && !getValues(`${prefix}building${suffix}`)
                ? "Street number or building name required"
                : true,
            deps: [`${prefix}building${suffix}`]
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="Building Name"
          name={`${prefix}building${suffix}`}
          rules={{
            validate: val =>
              !val && !getValues(`${prefix}building_number${suffix}`)
                ? "Street number or building name required"
                : true,
            deps: [`${prefix}building_number${suffix}`]
          }}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="Street"
          name={`${prefix}street${suffix}`}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="Post Town"
          name={`${prefix}post_town${suffix}`}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField label="Locale" name={`${prefix}locale${suffix}`} />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="County"
          name={`${prefix}region${suffix}`}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item sm={6}>
        <RHFTextField
          label="Country"
          name={`${prefix}country${suffix}`}
          rules={{ required: true }}
        />
      </Grid>
    </>
  );
};
