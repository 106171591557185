import React from "react";
import { Typography } from "@material-ui/core";
import Main from "../components/layout/Main";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer
} from "../components/LayoutContainers";

interface ForbiddenScreenProps {}

export const ForbiddenScreen: React.FC<ForbiddenScreenProps> = () => {
  return (
    <ProductContainer>
      <ViewContainer>
        <ContentContainer>
          <Main title="Forbidden">
            <Typography>
              You don't have permissions to access this page.
            </Typography>
          </Main>
        </ContentContainer>
      </ViewContainer>
    </ProductContainer>
  );
};
