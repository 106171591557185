import React from "react";
import { createRoutingObject } from "@akj-dev/affinity-wrapper";
import { Home } from "./screens/Home";

export const routes = createRoutingObject(
  [
    {
      path: "/",
      title: "Dashboard",
      subject: <Home />
    }
  ],
  "/billing"
);
