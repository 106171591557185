import queryString from "query-string";

import { AppSubdomains, APP_BRANDS, APP_SUBDOMAINS } from "./appSubdomains";

export const getPathForCrossAppRedirect = (
  newApp: AppSubdomains,
  pathname: string
) => {
  const hostname = window.location.hostname;
  let searchQuery = "";

  const query = queryString.parse(window.location.search);

  if (query?.brand) {
    searchQuery = `?brand=${query.brand}`;
  }

  if (hostname.includes("localhost")) {
    return `http://localhost:3000${pathname}${searchQuery}`;
  }

  const currentApp = hostname
    .split(".")
    .find(part => APP_SUBDOMAINS.includes(part));

  const currentBrand = hostname
    .split(".")
    .find(part => APP_BRANDS.includes(part));

  // No current app for home app
  const domain = currentApp
    ? hostname.substring(hostname.indexOf(".") + 1, hostname.length)
    : hostname;

  const isUat = domain.includes("uat");

  // Handling of home app redirects from brand subdomain
  if (!currentApp && currentBrand) {
    const baseDomain = isUat
      ? domain.replace(`${currentBrand}.uat.`, "")
      : domain.replace(`${currentBrand}.`, "");

    return `${window.location.protocol}//${currentBrand}.${
      isUat ? "uat." : ""
    }${newApp ? `${newApp}.` : ""}${baseDomain}${pathname}${searchQuery}`;
  }

  // Handling of home uat app redirects without brand subdomain
  if (!currentApp && isUat) {
    const baseDomain = domain.replace("uat.", "");

    return `${window.location.protocol}//uat.${newApp}.${baseDomain}${pathname}${searchQuery}`;
  }

  // Redirect to home app
  if (newApp === AppSubdomains.HOME) {
    return `${window.location.protocol}//${hostname.replace(
      `${currentApp}.`,
      ""
    )}${pathname}${searchQuery}`;
  }

  // Redirect to different app on subdomain
  if (currentApp) {
    return `${window.location.protocol}//${hostname.replace(
      currentApp,
      newApp
    )}${pathname}${searchQuery}`;
  }

  return `${window.location.protocol}//${newApp}.${domain}${pathname}${searchQuery}`;
};
