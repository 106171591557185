import React from "react";
import { Grid } from "@material-ui/core";
import { RHFTextField } from "../../../atoms/form-fields/RHFTextField";
import { IndexedCardInfo } from "../../../atoms/indexed-card-info";
import { isValidSortCode, isValidUKBankAccount } from "../helpers/validators";

interface PaymentProps {
  cardIndex: string;
}

export const Payment = ({ cardIndex }: PaymentProps) => {
  return (
    <IndexedCardInfo index={cardIndex} title="Payment">
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <RHFTextField
            label="Bank Name"
            name="bank_name"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Account Name"
            name="bank_account_name"
            rules={{
              required: true,
              validate: val => {
                return val.length <= 30
                  ? true
                  : "Must be 30 characters or less";
              }
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <RHFTextField
            label="Account Number"
            name="bank_account_number"
            rules={{ required: true, validate: isValidUKBankAccount }}
          />
        </Grid>
        <Grid item sm={6}>
          {/*// TODO: Strip hyphens on the sort code if they exist. see createAccount action*/}
          {/*// See: https://auroratarget.tpondemand.com/entity/8116-as-allow-xx-xx-xx-or.*/}
          {/*bank_account_sortcode: values.bank_account_sortcode.replace(/-/g, "")
          https://github.com/lorensr/react-payment/blob/master/src/CardForm.js ?
          https://reactjsexample.com/currency-textfield-for-react-material-ui/ ?
          */}
          <RHFTextField
            label="Sort Code"
            name="bank_account_sortcode"
            rules={{ required: true, validate: isValidSortCode }}
          />
        </Grid>
      </Grid>
    </IndexedCardInfo>
  );
};
