import React, { ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

export interface Props {
  show: boolean;
  title: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  cancelLabel: string;
  submitLabel: string;
  children: ReactNode;
}

export const FiltersDialog = (props: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.show} onClose={props.handleCancel}>
      <div className={classes.dialog}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button
            onClick={props.handleCancel}
            data-cy="cancel-filter-dialog-button"
          >
            {props.cancelLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleSubmit}
            data-cy="confirm-filter-dialog-button"
          >
            {props.submitLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(1)
    },
    buttons: {
      margin: theme.spacing(2),
      marginTop: theme.spacing(1)
    }
  })
);

export default FiltersDialog;
